import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { APP_CONFIG } from 'app.config';
import { blobToDataURL, dataURLToBlob } from 'blob-util';
import { HttpHelper } from './http/HttpHelper';
import map from 'lodash-es/map';
import isEmpty from 'lodash-es/isEmpty';
import uniqueId from 'lodash-es/uniqueId';
import find from 'lodash-es/find';
import { saveAs } from 'file-saver';
import { CONTRACT_URL } from 'app/modules/manage/contract/contract.constant';
import { timeout } from 'rxjs/operators';

const removeBase64Header = (data: string) => {
    if (!data) {
        return data;
    }
    const base64Header = 'base64,';
    const index = data.indexOf(base64Header);
    return index > -1 ? data.substring(index + base64Header.length) : data;
};

const addImageBase64Header = (data: string) => 'data:image/jpeg;base64,' + data;

const asFileArray = (files: FileList): File[] => {
    if (!files) {
        return [];
    }

    const result: File[] = [];
    for (let i = 0; i < files.length; i++) {
        result.push(files[i]);
    }
    return result;
};

const checkFileSize = (files: File[], maxFileSize: number) => {
    return !find(files, file => file.size > maxFileSize);
};

const readDataBase64 = (file: File) => {
    return new Promise<any>((resolve: any, reject: any) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            resolve({
                name: file.name,
                size: file.size,
                type: file.type,
                data: reader.result.toString(),
            });
        };
        reader.onerror = () => {
            reject({
                name: file.name,
                size: file.size,
                type: file.type,
                message: '',
            });
        };
    });
};

const readFiles = (files: File[]) => {
    return Promise.all(map(files, readDataBase64));
};

const getFileExt = (filename: string): string => {
    return filename.slice((filename.lastIndexOf('.') - 1 >>> 0) + 2);
};

@Injectable({
    providedIn: 'root'
})
export class DmsService {
    constructor(private _httpHelper: HttpHelper, private _sanitizer: DomSanitizer) {
    }

    // getMultiPartContent(identifier, responseType: 'blob' | 'url' | 'safeUrl' = 'blob'): Promise<any> {
    //     const params = `identifier=${encodeURIComponent(identifier)}`;
    //     return this._httpHelper.methodGetMultiPart(DMS_SERVICE_URL.GET_MULTIPART_CONTENT + '?' + params)
    //         .then(resData => {
    //             if (resData && resData.errorcode) {
    //                 return undefined;
    //             }
    //             const blob = resData;
    //             if (responseType === 'blob') {
    //                 return blob;
    //             }

    //             return blobToDataURL(blob).then(dataUrl => {
    //                 if (responseType === 'safeUrl') {
    //                     return this._sanitizer.bypassSecurityTrustUrl(dataUrl);
    //                 }
    //                 return dataUrl;
    //             });
    //         });
    // }

    getMultiPartContent(identifier, responseType: 'blob' | 'url' | 'safeUrl' = 'blob'): Promise<any> {
        const params = `identifier=${encodeURIComponent(identifier)}`;
        return this._httpHelper.methodGetMultiPart(DMS_SERVICE_URL.DOWNLOAD + '?' + params)
            .then(resData => {
                if (resData && resData.errorcode) {
                    return undefined;
                }
                const blob = resData;
                if (responseType === 'blob') {
                    return blob;
                }

                return blobToDataURL(blob).then(dataUrl => {
                    if (responseType === 'safeUrl') {
                        return this._sanitizer.bypassSecurityTrustUrl(dataUrl);
                    }
                    return dataUrl;
                });
            });
    }

    getMultiPartContentEnvelop(identifier, responseType: 'blob' | 'url' | 'safeUrl' = 'blob'): Promise<any> {
        const params = `identifier=${encodeURIComponent(identifier)}`;
        return this._httpHelper.methodGetMultiPart(DMS_SERVICE_URL.GET_MULTIPART_CONTENT_ENVELOP + '?' + params)
            .then(resData => {
                if (resData && resData.errorcode) {
                    return undefined;
                }
                const blob = resData;
                if (responseType === 'blob') {
                    return blob;
                }

                return blobToDataURL(blob).then(dataUrl => {
                    if (responseType === 'safeUrl') {
                        return this._sanitizer.bypassSecurityTrustUrl(dataUrl);
                    }
                    return dataUrl;
                });
            });
    }

    getPdfMultiPartContentEnvelop(identifier, responseType: 'blob' | 'url' | 'safeUrl' = 'blob'): Promise<any> {
        const params = `identifier=${encodeURIComponent(identifier)}`;
        return this._httpHelper.methodGetMultiPart(DMS_SERVICE_URL.GET_PDF_MULTIPART_CONTENT_ENVELOP + '?' + params)
            .then(resData => {
                if (resData && resData.errorcode) {
                    return undefined;
                }
                const blob = resData;
                if (responseType === 'blob') {
                    return blob;
                }

                return blobToDataURL(blob).then(dataUrl => {
                    if (responseType === 'safeUrl') {
                        return this._sanitizer.bypassSecurityTrustUrl(dataUrl);
                    }
                    return dataUrl;
                });
            });
    }

    getPdfDownloadAllEnvelope(envelopeAggId, responseType: 'blob' | 'url' | 'safeUrl' = 'blob'): Promise<any> {
        const params = `envelopeAggId=${encodeURIComponent(envelopeAggId)}`;
        return this._httpHelper.methodGetMultiPart(DMS_SERVICE_URL.GET_PDF_DOWNLOAD_ALL + '?' + params)
            .then(resData => {
                if (resData && resData.errorcode) {
                    return undefined;
                }
                const blob = resData;
                if (responseType === 'blob') {
                    return blob;
                }

                return blobToDataURL(blob).then(dataUrl => {
                    if (responseType === 'safeUrl') {
                        return this._sanitizer.bypassSecurityTrustUrl(dataUrl);
                    }
                    return dataUrl;
                });
            });
    }

    postMultiPartContent(formData: FormData, fileName: string = '') {
        const cmd = {
            'fileName': fileName,
            'extension': getFileExt(fileName)
        }
        formData.append('cmd', JSON.stringify(cmd));
        return this._httpHelper.methodPostMultiPart(DMS_SERVICE_URL.UPLOAD_FILE, formData);
    }

    postMultiPartContentLargeFile(formData: FormData, fileName: string = '') {
        const cmd = {
            'fileName': fileName,
            'extension': getFileExt(fileName)
        }
        formData.append('cmd', JSON.stringify(cmd));
        return this._httpHelper.methodPostMultiPart(DMS_SERVICE_URL.UPLOAD_LARGE_FILE, formData, 600000);
    }

    postMultiPartContentEnvelop(formData: FormData, fileName: string = '') {
        formData.append('fileName', fileName);
        formData.append('extension', getFileExt(fileName));
        return this._httpHelper.methodPostMultiPart(DMS_SERVICE_URL.UPLOAD_FILE_ENVELOP, formData);
    }

    saveAsBlob(blob, fileName: string): void {
        if (blob && !isEmpty(fileName)) {
            saveAs(blob, fileName);
        }
    }

    saveAsFromBase64(base64: string, fileName: string): void {
        if (!isEmpty(base64) && !isEmpty(fileName)) {
            const blob = dataURLToBlob(base64);
            saveAs(blob, fileName);
        }
    }

    delete(identifier: string): Promise<any> {
        const params = `?identifier=${encodeURIComponent(identifier)}`;
        return this._httpHelper.methodDeleteService(DMS_SERVICE_URL.DELETE + params);
    }

    deleteEnvelop(identifier: string): Promise<any> {
        const params = `?identifier=${encodeURIComponent(identifier)}`;
        return this._httpHelper.methodDeleteService(DMS_SERVICE_URL.DELETE_ENVELOP + params);
    }

    selectFile(accept: string = '*', maxFileSize: number = 3 * 1048576, multiple: boolean = false): Promise<any> {
        const id = uniqueId();
        const inputFile = document.createElement('input');
        inputFile.accept = accept;
        inputFile.multiple = multiple;
        inputFile.type = 'file';
        inputFile.name = id;
        inputFile.id = id;
        inputFile.setAttribute('style', 'display:none');
        const promise = new Promise<any>((resolve: any, reject: any) => {
            inputFile.onclick = () => {
                const eventName = 'mouseover';
                const useCapture = true;
                const onMouseover = () => {
                    document.body.removeEventListener(eventName, onMouseover, useCapture);
                    setTimeout(() => {
                        const files: File[] = asFileArray(inputFile.files);
                        if (!checkFileSize(files, maxFileSize)) {
                            return reject({
                                files: [],
                                message: 'ERROR_MAX_FILE_SIZE',
                            });
                        }
                        return resolve({
                            files,
                            message: null,
                        });
                    }, 100);
                };
                document.body.addEventListener(eventName, onMouseover, useCapture);
            };
            inputFile.click();
        });
        return promise;
    }

    selectAndUploadMultipartFiles(accept: string = '*', maxFileSize: number = 3 * 1048576, multiple: boolean = false): Promise<any> {
        return this.selectFile(accept, maxFileSize, multiple)
            .then(({ files }) => {
                if (files.length > 0) {
                    const arrayPromises = map(files, (file) => {
                        const formData: FormData = new FormData();
                        formData.append('file', file, file.name);
                        return this.postMultiPartContent(formData, file.name).then(data => {
                            if (data && data.errorcode) {
                                throw {
                                    message: data.message || 'SYSTEM_ERROR',
                                };
                            }
                            return data;
                        });
                    });
                    return Promise.all(arrayPromises);
                } else {
                    return [];
                }
            });
    }

    getResources(refCodes: Array<string>): Promise<any> {
        // const params = 'refCodes=' + JSON.stringify(refCodes);
        // return this._httpHelper.methodPostService(DMS_SERVICE_URL.DOWNLOAD_FILE, params, APP_CONFIG.QUERY_TIMEOUT);
        const params = `${JSON.stringify(refCodes)}`;
        return this._httpHelper.methodPostJsonService(DMS_SERVICE_URL.GET_RESOURCES, params);
    }

    getResourcesEnvelop(refCodes: Array<string>): Promise<any> {
        const params = `${JSON.stringify(refCodes)}`;
        return this._httpHelper.methodPostJsonService(DMS_SERVICE_URL.GET_RESOURCES_ENVELOP, params);
    }

    getMultiPartContentImage(identifier, fileName, responseType: 'blob' | 'url' | 'safeUrl' = 'blob'): Promise<any> {
        const params = `identifer=${encodeURIComponent(identifier)}`;
        return this._httpHelper.methodGetMultiPart(DMS_SERVICE_URL.GET_MULTIPART_CONTENT + '?' + params)
            .then(resData => {
                if (resData && resData.errorcode) {
                    return undefined;
                }
                const blob = resData;
                if (responseType === 'blob') {
                    return { 'blob': blob, 'fileName': fileName, 'identifier': identifier };
                }

                return blobToDataURL(blob).then(dataUrl => {
                    if (responseType === 'safeUrl') {
                        return this._sanitizer.bypassSecurityTrustUrl(dataUrl);
                    }
                    return dataUrl;
                });
            });
    }

    getAvatarContent(identifier, responseType: 'blob' | 'url' | 'safeUrl' = 'blob'): Promise<any> {
        const params = `identifer=${encodeURIComponent(identifier)}`;
        return this._httpHelper.methodGetMultiPart(DMS_SERVICE_URL.GET_AVATAR_CONTENT + '?' + params)
            .then(resData => {
                if (resData && resData.errorcode) {
                    return undefined;
                }
                const blob = resData;
                if (responseType === 'blob') {
                    return blob;
                }

                return blobToDataURL(blob).then(dataUrl => {
                    if (responseType === 'safeUrl') {
                        return this._sanitizer.bypassSecurityTrustUrl(dataUrl);
                    }
                    return dataUrl;
                });
            });
    }

    getUserAvatar(username, responseType: 'blob' | 'url' | 'safeUrl' = 'blob'): Promise<any> {
        const params = `username=${encodeURIComponent(username)}`;
        return this._httpHelper.methodGetMultiPart(DMS_SERVICE_URL.GET_USER_AVATAR + '?' + params)
            .then(resData => {
                if (resData && (resData.errorcode || resData.type.indexOf('image') === -1)) {
                    return undefined;
                }
                const blob = resData;
                if (responseType === 'blob') {
                    return blob;
                }

                return blobToDataURL(blob).then(dataUrl => {
                    if (responseType === 'safeUrl') {
                        return this._sanitizer.bypassSecurityTrustUrl(dataUrl);
                    }
                    return dataUrl;
                });
            });
    }

    downloadMulti(identifiers, responseType: 'blob' | 'url' | 'safeUrl' = 'blob'): Promise<any> {
        let request = { "identifiers": identifiers };
        const params = `${JSON.stringify(request)}`;
        return this._httpHelper.methodPostJsonService(DMS_SERVICE_URL.DOWNLOAD_MULTI, params, APP_CONFIG.COMMAND_TIME_OUT)
            .then(resData => {
                if (resData && resData.errorcode) {
                    return undefined;
                }
                const blob = resData;
                if (responseType === 'blob') {
                    return blob;
                }

                return blobToDataURL(blob).then(dataUrl => {
                    if (responseType === 'safeUrl') {
                        return this._sanitizer.bypassSecurityTrustUrl(dataUrl);
                    }
                    return dataUrl;
                });
            });
    }

    getFileLocal(url): Promise<any> {
        return this._httpHelper.methodGetMultiPart(url)
            .then(resData => {
                const blob = resData;
                // return blobToDataURL(blob).then(dataUrl => {
                //     return this._sanitizer.bypassSecurityTrustUrl(dataUrl);
                // });
                return blob;
            });
    }

    getMultiPartContentMain({ identifier, accessToken, responseType = 'blob', fileName, isDownloadAll }: any, isPreview: boolean): Promise<any> {
        // const params = `identifier=${encodeURIComponent(identifier)}`;
        const docRegex = new RegExp('^.+\.(doc|docx)$');
        const excelRegex = new RegExp('.*\.(xlsx|xls|csv)');
        let params = '';
        // if (fileName && (docRegex.test(fileName) || excelRegex.test(fileName))) {
        //     isPreview = true;
        // }

        // if(accessToken || isDownloadAll){
        //     params = DMS_SERVICE_URL.DOWNLOAD_FILE_VNG_CLOUND;
        // } else {
        //     params = CONTRACT_URL.VIEW_FILE  + identifier;
        // }
        params = DMS_SERVICE_URL.DOWNLOAD_FILE_VNG_CLOUND;


        return this._httpHelper.methodPostMultiPartWithCustomHeader(params, { identifer: identifier, preview: isPreview, accessToken: accessToken, appCode: APP_CONFIG.APP_CODE }, responseType)
            .then(resData => {
                if (resData && resData.errorcode) {
                    return undefined;
                }
                const blob = resData;
                if (responseType === 'blob') {
                    return blob;
                }
                if (responseType === 'arraybuffer') {
                    return blob;
                }

                return blobToDataURL(blob).then(dataUrl => {
                    if (responseType === 'safeUrl') {
                        return this._sanitizer.bypassSecurityTrustUrl(dataUrl);
                    }
                    return dataUrl;
                });
            });
    }

    async getAccessTokenView(refCodes, isIncludeDoc?: boolean) {
        const params = {
            "refCodes": refCodes,
            "includePreviewDoc": true
        };

        return this._httpHelper.methodPostJsonService(DMS_SERVICE_URL.REQUEST_TOKEN, params);
    }

    async getAccessTokenDownload(refCodes) {
        let params = {
            "refCodes": refCodes
        };
        return this._httpHelper.methodPostJsonService(DMS_SERVICE_URL.REQUEST_TOKEN, params);
    }

    getSelfieContent(identifier, responseType: 'blob' | 'url' | 'safeUrl' = 'blob'): Promise<any> {
        const params = `identifier=${encodeURIComponent(identifier)}`;
        return this._httpHelper.methodGetMultiPart(DMS_SERVICE_URL.DOWNLOAD + '?' + params)
            .then(resData => {
                if (resData && resData.errorcode) {
                    return undefined;
                }
                const blob = resData;
                if (responseType === 'blob') {
                    return { 'blob': blob, 'identifier': identifier };
                }

                return blobToDataURL(blob).then(dataUrl => {
                    if (responseType === 'safeUrl') {
                        return this._sanitizer.bypassSecurityTrustUrl(dataUrl);
                    }
                    return dataUrl;
                });
            });
    }

}


export class DMS_SERVICE_URL {
    static UPLOAD_FILE = APP_CONFIG.MCRDMS +  'resource/upload';
    static UPLOAD_LARGE_FILE = APP_CONFIG.MCR_BULK_UPLOAD +  'large/bulkupload';
    static GET_RESOURCES = APP_CONFIG.MCRDMS + 'resource/infos';
    static DOWNLOAD = APP_CONFIG.MCRDMS + 'resource/download';
    static GET_MULTIPART_CONTENT = APP_CONFIG.MCRDMS + 'resource/download';
    static DELETE = APP_CONFIG.MCRDMS + 'resource/delete';

    static GET_USER_AVATAR = APP_CONFIG.HOST_NAME + 'services/mcrowldms/api/dms/avatar/userAvatar';
    static DOWNLOAD_MULTI = '';
    static GET_AVATAR_CONTENT = '';

    static UPLOAD_FILE_ENVELOP = APP_CONFIG.HOST_NAME + 'services/mcrowldms/api/envelope/upload';
    static GET_MULTIPART_CONTENT_ENVELOP = APP_CONFIG.HOST_NAME + 'services/mcrowldms/api/envelope/downloadPdf';
    static GET_PDF_MULTIPART_CONTENT_ENVELOP = APP_CONFIG.HOST_NAME + 'services/mcrowldms/api/envelope/pdf/download';
    static GET_PDF_DOWNLOAD_ALL = APP_CONFIG.HOST_NAME + 'services/mcrowldms/api/envelope/pdf/downloadAll';
    static GET_RESOURCES_ENVELOP = APP_CONFIG.HOST_NAME + 'services/mcrowldms/api/envelope/resources';
    static DELETE_ENVELOP = APP_CONFIG.HOST_NAME + 'services/mcrowldms/api/envelope/resource';
    static REQUEST_TOKEN = APP_CONFIG.HOST_NAME_GW + 'sta/auth/requestToken';
    static DOWNLOAD_FILE_VNG_CLOUND = APP_CONFIG.HOST_NAME_VNG_CLOUD + 'pub/files/download';
    static GET_SELFIE_CONTENT = APP_CONFIG.DMS_RESOURCE + 'api/selfie/download';
}

