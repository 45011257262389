import { UtilComponent } from './../../../shared/common/utils/UtilComponent';
import { ProfileUserService } from './../../../modules/profile/profile-user/profile-user.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { BooleanInput } from '@angular/cdk/coercion';
import { Subject, takeUntil } from 'rxjs';
import { User } from 'app/core/user/user.types';
import { UserServiceHeader } from 'app/core/user/user.service';
import { AppState } from 'app/shared/common/utils/AppState';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AboutComponent } from './modal/about/about.component';
import { UtilCommon } from 'app/shared/common/utils/UtilCommon';
import { PROFILE_USER } from 'app/modules/profile/profile-user/profile-user.constant';
import { DmsService } from 'app/shared/common/utils/DmsService';
import { DomSanitizer } from '@angular/platform-browser';
import { PopUpUserTenant } from './popup-user-tenant/popup-user-tenant';
import { AppStateConstant } from 'app/shared/common/constants/AppState.constant';

@Component({
    selector: 'user',
    templateUrl: './user.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'user'
})
export class UserComponent implements OnInit, OnDestroy {
    /* eslint-disable @typescript-eslint/naming-convention */
    static ngAcceptInputType_showAvatar: BooleanInput;
    /* eslint-enable @typescript-eslint/naming-convention */

    @Input() showAvatar: boolean = true;
    isImage: boolean = false;
    user: User;
    image: any;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    private _popupUserTenant: MatDialogRef<PopUpUserTenant>;

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _router: Router,
        private _userService: UserServiceHeader,
        private _appState: AppState,
        private _matDialog: MatDialog,
        private _profileUserService: ProfileUserService,
        private _dmsService: DmsService,
        private _domSanitizer: DomSanitizer,
        private _utilComponent: UtilComponent,
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    ngAfterViewInit() {
        this._userService._isTriggerUpdateAvatar
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(isTrigger => {
                if (isTrigger) {
                    this.getAvatar();
                    this._userService._isTriggerUpdateAvatar.next(false);
                }
            });
    }
    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to user changes
        this._userService.user$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user: User) => {
                this.user = user;
                // const appState = this._appState.getUserinfo();
                // if (UtilCommon.isNotNull(appState) && UtilCommon.isNotNull(appState.moreInfo)) {
                //     this.user.email = appState?.moreInfo?.contactEmail;
                //     this.user.tenantCode = appState?.moreInfo?.tenantCode;
                //     this.user.companyName = appState?.moreInfo?.companyName;
                // }
                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
        this.getAvatar();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Update the user status
     *
     * @param status
     */
    updateUserStatus(status: string): void {
        // Return if user is not available
        if (!this.user) {
            return;
        }

        // Update the user
        this._userService.update({
            ...this.user,
            status
        }).subscribe();
    }

    /**
     * Sign out
     */
    signOut(): void {
        this._appState.clearLogout(true);
        this._router.navigate(['/sign-in']);
    }

    openAbout(): void {
        this._matDialog.open(
            AboutComponent, {
            autoFocus: false,
            panelClass: "remove-scroll",
            width: '300px',
            height: '300px'
        }
        );
    }

    openProfile(): void {
        this._router.navigate(['/settings/profile']);
    }

    async getAvatar() {
        try {
            const responseEmpResource = await this._profileUserService.getEmpResource(PROFILE_USER.AVATAR);
            if(responseEmpResource){
                for (const itemEmpResource of responseEmpResource) {
                    if (itemEmpResource.isDefault === PROFILE_USER.IS_DEFAULT_ALWAY_USE) {
                        const identifier = UtilCommon.isNotNull(itemEmpResource.identifier) ? itemEmpResource.identifier : '';
                        this.downloadAvatarExport(identifier, "",)
                        break;
                    }
                }
            }
        } catch (err) {
            // tslint:disable-next-line: no-console
            console.log('err', err)
        }

    }

    downloadAvatarExport(identifier: string, fileName: string): void {
        this._dmsService.getMultiPartContent(identifier).then(blob => {
            if (!blob) {
                this._utilComponent.showTranslateSnackbar('SYSTEM_ERROR', 'error');
            } else {
                const urlCreator = window.URL || window['webkitURL'];
                const imageSrc = urlCreator.createObjectURL(blob);
                const img = this._domSanitizer.bypassSecurityTrustResourceUrl(imageSrc);
                this.image = img;
                this.isImage = true;
                this._changeDetectorRef.markForCheck();
            }
        }).catch(error => {
            // tslint:disable-next-line: no-console
            console.error('Error when download file: ${identifier}', error);
            this._utilComponent.showTranslateSnackbar('SYSTEM_ERROR', 'error');
        });
    }

    openChangeTenant(): void {
        this._popupUserTenant = this._matDialog.open(PopUpUserTenant, {
            data: {
            },
            id: 'matPopUpUserTenant',
        });
        this._popupUserTenant.afterClosed().subscribe(result => {
            if (result) {
                localStorage.setItem(AppStateConstant.ServerToken, result.id_token);
                this._appState.serverToken = result?.id_token;
                setTimeout(() => {
                    location.reload();
                }, 300);
            }
        });
    }

    public openPersonalPolicy() {
        window.open('/#/personal-policy', '_blank');
    }

    public openTermOfUse() {
        window.open('/#/term-of-use', '_blank');
    }
}
